<template>
  <form
    ref="form"
    method="post"
    :action="formData.checkout_url"
  >
    <input
      type="hidden"
      name="merchant_id"
      :value="formData.merchant_id"
    >
    <input
      type="hidden"
      name="return_url"
      :value="formData.return_url"
    >
    <input
      type="hidden"
      name="cancel_url"
      :value="formData.cancel_url"
    >
    <input
      type="hidden"
      name="notify_url"
      :value="formData.notify_url"
    >

    <input
      type="hidden"
      name="order_id"
      :value="formData.order_id"
    >
    <input
      type="hidden"
      name="items"
      :value="formData.items"
    >
    <input
      type="hidden"
      name="currency"
      :value="formData.currency"
    >
    <input
      type="hidden"
      name="amount"
      :value="formData.amount"
    >

    <input
      type="hidden"
      name="first_name"
      :value="formData.first_name"
    >
    <input
      type="hidden"
      name="last_name"
      :value="formData.last_name"
    >
    <input
      type="hidden"
      name="email"
      :value="formData.email"
    >
    <input
      type="hidden"
      name="phone"
      :value="formData.phone"
    >
    <input
      type="hidden"
      name="address"
      :value="formData.address"
    >
    <input
      type="hidden"
      name="city"
      :value="formData.city"
    >
    <input
      type="hidden"
      name="country"
      :value="formData.country"
    >
    <input
      type="hidden"
      name="hash"
      :value="formData.hash"
    >
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      block
      variant="primary"
      @click="submitForm"
    >
      Pay Now
    </b-button>
  </form>
</template>

<script>
import {
  BCard, BCardBody, BCardText, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    submitForm() {
        // console.log(this.$refs);
      this.$refs.form.submit()
    },
  },
}
</script>

<style></style>
